import React, { useEffect, useState } from 'react'
import Layout from '../components/layout'
import Scrollable from '../components/Scrollable'
import PageHeader from '../components/PageHeader'
import ViewMore from '../components/ViewMoreProducts'
import ProductList from '../components/ProductList'
import DropletsBackground from '../images/droplets-01.png'

import { TranslationConstants } from '../Constants'
import Footer from '../components/Footer';

const Products = (props) => {
    const blue = '#1d3a6d';
    return (
        <Layout>
            <PageHeader showDroplets fontFamily={'AmanticBold'} background={'white'} color={blue} headercenter={TranslationConstants.holsumBread} translation />
            <ProductList />
            <Footer />
        </Layout>
    )
}

export default Products