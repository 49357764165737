import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types'
import style from './style.module.scss';
import { ProductCell, LoadingView } from '../';
import { connect } from 'react-redux';

const ProductList = (props) => {

    let [isLoaded, toggleLoading] = useState(false);
    useEffect(() => {
        let iterator = 0;
        toggleLoading(false)

        props.products.forEach((item, key) => {
            //Destructure the photos array OR the photo object. One of them will be empty so we set defaults.
            let { photos = [] } = item;
            //Get the url of the image...
            let url = photos[0] ? photos[0].location || null : null;
            //Start loading the image...
            var img = new Image();
            img.src = url;
            if (url == null) iterator++;
            else {
                img.onload = () => {
                    iterator++;
                    if (iterator == props.products.length) toggleLoading(true)
                }
            }
        })
    }, [props])
    return (
        <div className={style.productWrapper}>
            <div className={style.recipesWrapper}>
                {
                    !props.getProductsLoading && isLoaded ? props.products.map((item, key) => <ProductCell key={key} index={key} data={item} />) : <LoadingView label="products" />
                }
            </div>
        </div>
    )
}

export default connect((state) => {
    return {
        getProductsLoading: state.getProductsLoading,
        getProductsFailed: state.getProductsFailed,
        products: state.products
    }
})(ProductList)